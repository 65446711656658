.amplify-button:hover {
  background: darkgray;
}
.amplify-button[data-variation='primary'] {
  background: #02375a;
}
.amplify-tabs {
  display: none;
}
.login{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  height: 100%;
}