.display-container{
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: auto !important;
}
.entrycontainer{
    display: flex;
    justify-content: space-between;
    
}

.global-container{
    margin-right: 20px;
}

.entry-container{
    display: flex;
    align-items: center;
}
.values-container{
    display:flex;
    margin-top: '5px' !important;
    justify-content: center !important;
}
.button-container{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.close-button{
    display: flex;
    flex: auto;
    justify-content: space-between !important;
}

.page-selector{
    display: flex !important;
    align-items: center;
}
.pagination{
    display: flex;
    justify-content: flex-end;

}
.section-container{
    height:100%;
}
.add-section-button{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}
.editor-container{
    margin: 10px;
}

.container-cliensections{
    height:100% !important
}