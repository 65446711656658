.display-container{
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: auto !important;
}
.entrycontainer{
    display: flex;
    justify-content: space-between;
    
}

.global-container{
    margin-right: 20px;
}

.entry-container{
    display: flex;
    align-items: center;
}
.values-container{
    display:flex;
    margin-top: '5px' !important;
    justify-content: center !important;
}
.button-container{
    display: flex;
    justify-content: center !important;
    margin-top: 15px;
}

.close-button{
    display: flex;
    justify-content: space-between !important;
}
.page-selector{
    display: flex !important;
    align-items: center;
}
.pagination{
    display: flex;
    justify-content: flex-end;

}
.details-background{
    background-color: #f2f5f3;
}

.cell-style{
    padding-left: 0.1 !important;
    padding-top: 16 !important;
    padding-right: 0 !important;
    padding-bottom: 16 !important;
}
.reset-password{
    display: flex;
    justify-content: end;
    align-items: center;
}