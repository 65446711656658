.display-container{
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: auto !important;
}
.entrycontainer{
    display: flex;
    justify-content: space-between;
    
}
.create-new{
    display: flex
}

.global-container{
    margin-right: 20px;
}

.create-entry-container{
    display: flex;
    align-items: center;
}
.values-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.button-container{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.close-button{
    display: flex;
    justify-content: space-between !important;
}
.page-selector{
    display: flex !important;
    align-items: center;
}
.pagination{
    display: flex;
    justify-content: flex-end;

}
.csv-button{
    display: flex;
    border: 1px solid rgba(2, 55, 90, 0.5);
    color: #02375a;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    border-radius:4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    margin: 10px;

    
}
.editable{
    display:flex;
    justify-content: space-between;
    flex: 1;
    width:10%
    
}